<template>
  <div class="businessMap" id="businessMap">
    <div id="container" :style="{ height: oHight }"></div>
    <div class="businessMap-b">
      <input
        type="text"
        placeholder="请输入关键字,搜出结果,选择对应的点位"
        id="tipinput"
      />
      <div class="txt">地址：{{ address }}</div>
      <div class="submit" @click="submit">确定</div>
    </div>
  </div>
</template>

<script>
import { mapSearchUrl } from "./api";
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "14e8f6657d33d80943c81fd846b0735b",
};
export default {
  name: "businessMap",
  data() {
    return {
      map: "",
      oHight: "",
      address: "",
      marker: "",
      latitude: "",
      longitude: "",
      newlatitude: "",
      newlongitude: "",
      geocoder: "",
      lnglat: [],
    };
  },
  async created() {},
  computed: {},
  mounted() {
    let oC = document.documentElement.clientWidth / 750;
    this.oHight = document.documentElement.clientHeight - 376 * oC + "px";
    this.latitude = localStorage.getItem("latitude");
    this.longitude = localStorage.getItem("longitude");
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    search() {
      //输入提示
    },
    submit() {
      this.$router.replace({
        name: "peopleReactionForm",
        query: {
          detailedAddress: this.address,
          latitude: this.newlatitude,
          longitude: this.newlongitude,
        },
      });
    },
    getAdress(lnglat) {
      let _this = this;
      this.geocoder.getAddress(lnglat, function (status, result) {
        if (status === "complete" && result.regeocode) {
          console.log(result, "99");
          _this.address = result.regeocode.formattedAddress;
        } else {
        }
      });
    },
    initAMap() {
      let _this = this;
      AMapLoader.load({
        key: "6ccc57a1965684bcf1d56bf846608b44", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.ControlBar",
          "AMap.Geocoder",
          "AMap.Marker",
          "AMap.CitySearch",
          "AMap.Geolocation",
          "AMap.AutoComplete",
          "AMap.InfoWindow",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // 获取到作为地图容器的DOM元素，创建地图实例
          this.map = new AMap.Map("container", {
            //设置地图容器id
            resizeEnable: true,
            viewMode: "3D", // 使用3D视图
            zoomEnable: true, // 地图是否可缩放，默认值为true
            dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
            doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
            zoom: 11, //初始化地图级别
            center: [_this.longitude, _this.latitude], // 初始化中心点坐标 广州
            // mapStyle: "amap://styles/darkblue", // 设置颜色底层
          });
          //初始化获取地址
          if (this.geocoder) {
            this.map.remove(this.geocoder);
          }
          this.geocoder = new AMap.Geocoder({});
          if (this.latitude && this.longitude) {
            this.lnglat = [this.longitude, this.latitude];
            this.getAdress(this.lnglat);
          }

          // 点击地图上的位置，根据经纬度转换成详细地址
          this.map.on("click", (e) => {
            console.log(e, "999");
            let lat = e.lnglat.lat;
            let lng = e.lnglat.lng;
            // 创建一个 Marker 实例：
            if (this.marker) {
              this.map.remove(this.marker);
            }
            this.marker = new AMap.Marker({
              position: new AMap.LngLat(lng, lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: "",
            });
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(this.marker);
            this.lnglat = [lng, lat];
            this.newlatitude = lat;
            this.newlongitude = lng;
            this.getAdress(this.lnglat);
          });
          var autoOptions = {
            input: "tipinput",
          };
          AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], function () {
            var auto = new AMap.AutoComplete(autoOptions);
            var placeSearch = new AMap.PlaceSearch({
              map: _this.map,
            }); //构造地点查询类
            auto.on("select", select); //注册监听，当选中某条记录时会触发
            function select(e) {
              placeSearch.setCity(e.poi.adcode);
              placeSearch.search(e.poi.name); //关键字查询查询
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 地图点击点击事件
    handlemapclickbtn(e) {
      console.log(e, "9999");
    },
  },
};
</script>
<style lang="less" scoped>
.businessMap {
  #container {
    width: 100%;
    height: 800px;
  }
  .businessMap-b {
    position: relative;
    height: 376px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 30px 30px 0 0;
    input {
      width: 100%;
      border: 1px solid #ffbf54;
      height: 60px;
      padding: 0 20px;
      color: #fd9f2c;
      font-size: 28px;
      line-height: 60px;
      &::placeholder {
        color: #fd9f2c;
        font-size: 28px;
        line-height: 60px;
      }
    }
    .txt {
      padding: 38px 56px;
      height: 210px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 28px;
      color: #666666;
      line-height: 40px;
      overflow-y: auto;
    }
    .submit {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      margin: auto;
      width: 476px;
      height: 64px;
      background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
      border-radius: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 30px;
      color: #ffffff;
      line-height: 64px;
      text-align: center;
      margin: 0 auto;
    }
  }
}
</style>
